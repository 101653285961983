import { useRouter } from 'next/router'
import { useSelector } from 'react-redux'

import { Dashboard as DashboardContainer } from '../containers'

const Index = () => {
  const router = useRouter()
  const { roleMapping } = useSelector((state) => state.usersLoginReducer)

  if (roleMapping.find((role) => role.path.includes('dashboard'))) {
    return <DashboardContainer />
  } else {
    router.push(roleMapping[0].path)
    return null
  }
}

export default Index
